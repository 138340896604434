.navbar {
	/* background-color: rgb(20 36 48); */
	/* background-color: rgb(238, 238, 238); */
	border-bottom: 1px solid rgba(0, 0, 0, 0.23);
	color: #000;
	padding: 8px 0;
	/* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
	position: fixed;
	width: 100%;
	top: 0;
	z-index: 100;
	background-color: #fff;
}

.navbar .container,
.navbar .container-fluid {
	@media (min-width: 768px) {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}

.container {
	max-width: 1830px;
	width: 100%;
	padding-left: 15px;
	padding-right: 15px;
	margin-left: auto;
	margin-right: auto;
}

.container-fluid {
	max-width: 100%;
	width: 100%;
	padding-left: 15px;
	padding-right: 15px;
	margin-left: auto;
	margin-right: auto;
}

.nav-links {
	list-style: none;
	display: flex;
	margin: 0;
	padding: 0;
	margin-left: 25px;
}

.nav-links li {
	margin-right: 20px;
}

.nav-links a {
	text-decoration: none;
	color: black;
	font-weight: 500;
	font-size: 16px;
	transition: color 0.2s;
}

.nav-links a:hover {
	color: #ff5733;
	/* Hover color for links */
}

/* .search-bar {
	margin-left: auto;
	max-width: 600px;
	padding-right: 20px;
	display: flex;
	gap: 20px;
}

.search-bar input {
	padding: 10px;
	border: 1px solid rgba(0, 0, 0, 0.23);
	border-radius: 4px;
	font-size: 14px;
	width: 100%;
	max-width: 600px;
	margin-right: 0;
	transition: all 0.5s ease-in-out;
	outline: none !important;
}

.search-bar input:focus {
	border-color: rgba(0, 0, 0, 0.80);
}

.search-bar button {
	background-color: #ff5733;
	color: #fff;
	border: none;
	border-radius: 4px;
	padding: 10px 20px;
	cursor: pointer;
	font-size: 14px;
}

.search-bar button:hover {
	background-color: #ff2d00;
} */

.nav-right-content {
	display: flex;
	margin-left: auto;
	align-items: center;
	gap: 10px;
}

/* .masonry-grid {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
	gap: 16px;
	margin: 0 auto;
	margin-top: 100px;
  } */

.masonry-grid {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
	gap: 16px;
	margin: 0 auto;
	margin-top: 65px;
}

.masonry-item {
	position: relative;
	/* margin: 0;
	flex: 1;
	padding: 15px;
	max-width: 300px;
	border: 1px solid #ccc;
	border-radius: 8px;
	background-color: #fff;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
	transition: transform 0.2s;
	cursor: pointer; */
}

.masonry-image {
	overflow: hidden;
	position: relative;
	border-radius: 15px;
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
	cursor: pointer;

	transition: all 0.2s ease-in-out;
}

.masonry-item:hover .masonry-image {
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
}

.masonry-image img {
	width: 100%;
	/* height: 100%; */
	max-height: 800px;
	display: block;
	object-fit: cover;
	transition: transform 0.4s;
	/* Default cursor style */
}

.masonry-item:hover img {
	transform: scale(1.05);
}

/* Style for the checkbox container */
.masonry-check {
	position: absolute;
	right: 19px;
	top: 15px;
	z-index: 1;
	width: 20px;
	height: 20px;
}

.masonry-item input[type="checkbox"] {
	position: absolute;
	left: 0;
	top: 0;
	/* Ensure checkbox is above the image */
	width: 100%;
	/* Set the width of the checkbox */
	height: 100%;
	/* Set the height of the checkbox */
	opacity: 0;
	/* Hide the default checkbox */
}

/* Style for the custom checkbox */
.masonry-item input[type="checkbox"]+label {
	width: 23px;
	height: 23px;
	background-color: #fff;
	/* Background color of the checkbox */
	border: 2px solid #112d4a;
	/* Border color */
	border-radius: 4px;
	/* Rounded corners */
	cursor: pointer;
	display: inline-block;
	vertical-align: top;
	transition: background-color 0.2s, border-color 0.2s;
}

/* Style for the custom checkbox when checked */
.masonry-item input[type="checkbox"]:checked+label {
	background-color: #112d4a;
	/* Background color when checked */
	border-color: #112d4a;
	/* Border color when checked */
}

/* Style for the custom checkbox when checked with a checkmark */
.masonry-item input[type="checkbox"]:checked+label::after {
	content: '\2713';
	/* Checkmark symbol */
	display: block;
	color: #fff;
	/* Checkmark color */
	text-align: center;
	font-size: 30px;
	/* Adjust the size of the checkmark */
	line-height: 100%;
	height: 100%;
	margin-top: -12px;
}

/* Style for the custom checkbox label */
.masonry-item label {
	width: 100%;
	height: 100%;
}


.masonry-item p {
	/* margin-top: 8px; */
	text-align: center;
	font-weight: bold;
	margin-bottom: 0;
}

.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.8);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}

.modal-content {
	background-color: #fff;
	padding: 30px 20px 12px 20px;
	border-radius: 4px;
	position: relative;
	text-align: center;
	display: flex;
	flex-direction: column;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.modal-image-container {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.modal img {
	max-width: 100%;
	max-height: 80vh;
}

.modal-nav {
	/* display: flex; */
	justify-content: space-between;
	/* margin-top: 15px; */
	/* Adjust margin as needed */
}

.btnPrev {
	margin-right: 10px;
}

.btnNext {
	margin-left: 10px;
}

.btnPrev,
.btnNext {
	cursor: pointer;
	font-size: 22px;
	/* Adjust the font size as needed */
}

.close-icon {
	position: absolute;
	top: 7px;
	right: 12px;
	cursor: pointer;
}

.close-icon i {
	font-size: 24px;
	color: #333;
}

/* .masonry-item.selected {
	border: 2px solid #ff5733;
  } */

.drawer {
	position: fixed;
	top: 0;
	right: -400px;
	/* Start the drawer off-screen */
	width: 400px;
	max-width: 100%;
	/* Adjust the width as needed */
	height: 100%;
	background-color: #fff;
	box-shadow: -5px 0 5px rgba(0, 0, 0, 0.2);
	transition: right 0.3s ease-in-out;
	z-index: 999;
}

.drawer.open {
	right: 0;
	/* Show the drawer by moving it to the right */
}

.drawer-content {
	position: relative;
	padding: 20px;
	padding-top: 5px;
	height: 100%;
	overflow-y: auto;
	/* Enable scrolling for the content if it overflows */
}

.drawer h2 {
	font-size: 20px;
	margin-bottom: 15px;
	text-align: left;
	padding-right: 20px;
}

/* .drawer-col {
	margin-top: 20px;
} */

.drawer-col li {
	padding-bottom: 10px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.23);
}

.drawer ul {
	list-style: none;
	padding: 0;
}

.drawer li {
	margin-bottom: 10px;
}

.drawer button {
	background-color: #ff5733;
	color: #fff;
	border: none;
	border-radius: 4px;
	padding: 10px 20px;
	cursor: pointer;
	font-size: 14px;
	margin-top: 8px;
	width: 100%;
}

.drawer-image {
	width: 50px;
	/* Set the width as needed */
	height: 50px;
	/* Set the height as needed */
	border-radius: 8px;
	overflow: hidden;
	cursor: pointer;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
	margin-right: 10px;
}

.selected-image {
	width: 100%;
	height: 100%;
	object-fit: cover;
	/* Add spacing between images and text */
	display: inline-block;
	vertical-align: middle;
	/* Add rounded corners */
	/* Add a subtle shadow */
	transition: transform 0.2s;
	/* Add a hover effect */
}

.drawer-image:hover .selected-image {
	transform: scale(1.1);
	/* Zoom in on hover */
}


.close-drawer-icon {
	position: absolute;
	top: 22px;
	/* Adjust the top position as needed */
	right: 20px;
	/* Adjust the right position as needed */
	font-size: 20px;
	/* Adjust the icon size as needed */
	cursor: pointer;
	color: #112d4a;
	/* Adjust the icon color as needed */

}

.form-close-drawer-icon {
	position: absolute;
	top: 15px;
	/* Adjust the top position as needed */
	right: 5px;
	/* Adjust the right position as needed */
	font-size: 20px;
	/* Adjust the icon size as needed */
	cursor: pointer;
	color: #112d4a;
	/* Adjust the icon color as needed */
	margin-right: 20px;
}

.show-selected-button {
	color: #112d4a;
	background: none;
	border: none;
	border-radius: 50%;
	padding: 10px;
	cursor: pointer;
	font-size: 25px;
	margin-left: 10px;
	margin-left: 0;
	position: relative;
	/* To position the count badge */
	display: flex;
	align-items: center;
	justify-content: center;
}

.delete-selected-button {
	color: #112d4a;
	background: none;
	border: none;
	border-radius: 50%;
	padding: 10px;
	cursor: pointer;
	font-size: 25px;
	margin-left: auto;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 20px;
	margin-left: auto;
}

/* .show-selected-button:hover {
	background-color: #4CAF50; 
  } */

.my-masonry-grid {
	display: -webkit-box;
	/* Not needed if autoprefixing */
	display: -ms-flexbox;
	/* Not needed if autoprefixing */
	display: flex;
	/* gutter size offset */
	width: auto;
	margin-top: 50px;
	gap: 16px;

}

.my-masonry-grid_column {
	/* gutter size */
	background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column>div {
	/* change div to reference your elements you put in <Masonry> */
	margin-bottom: 30px;
}

/* .masonry-overlay {
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	z-index: 1;
	visibility: hidden;
	opacity: 0;
	background-color: rgba(0, 0, 0, 0.8);
	transition: all 0.4s ease-in-out;
	display: flex;
	align-items: center;
	justify-content: center;
} */

.masonry-overlay i {
	color: #fff;
	font-size: 30px;
	transition: all 0.4s ease-in-out;
	transform: scale(0.1);

}

.masonry-item:hover .masonry-overlay {
	visibility: visible;
	opacity: 1;
}

.masonry-item:hover .masonry-overlay i {
	transform: scale(1);
}

/* YourImageComponent.css */

.select-image-container {
	display: flex;
	align-items: center;
	gap: 5px;
}

.masonry-text {
	padding-top: 15px;
	position: relative;
	padding-right: 40px;
}

.masonry-text p+p {
	margin-top: 6px;
}

.masonry-text p {
	font-size: 14px;
}

.image-container {
	/* margin: 0 auto;
	max-width: 1200px; */
	/* Adjust the maximum width as needed */
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
	.image-container {
		max-width: 90%;
		/* Adjust the maximum width for smaller screens */
	}
}

/* Responsive styles for even smaller screens, e.g., mobile */
@media (max-width: 480px) {
	.image-container {
		max-width: 100%;
		/* Full width for the smallest screens */
		padding: 0 10px;
		/* Add horizontal padding for better mobile display */
	}
}


.attractive-text {
	/* background: url('../../backGround-image/italica.jpg'); */
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	color: black;
	text-align: center;
	margin-top: 78px;
	position: relative;

	@media (max-width: 767px) {
		margin-top: 129px;
	}
}

.attractive-text:after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	/* background-color: rgba(0, 0, 0, 0.8); */
	z-index: 1;
}

.attractive-text .container {
	z-index: 11;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding-top: 35px;
	padding-bottom: 35px;
	/* min-height: 300px; */
}

.attractive-text h1 {
	font-size: 36px;
	margin: 0 0 12px;
	padding: 0;
}

.attractive-text p {
	font-size: 18px;
	margin-top: 10px;
	font-family: 'Your-Italic-Font', sans-serif;
	font-style: italic;
	/* This is to ensure it's italic */
}

.image-card {
	border: 1px solid #ccc;
	border-radius: 8px;
	padding: 16px;
	background-color: #fff;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
	transition: transform 0.2s;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.image-card img {
	width: 100%;
	max-height: 150px;
	/* Adjust the maximum height as needed */
	object-fit: cover;
	border-radius: 8px 8px 0 0;
}

.image-card p {
	margin-top: 8px;
	text-align: center;
	font-weight: bold;
}

.image-card:hover {
	transform: scale(1.05);
}

.load-more-button {
	text-align: center;
	margin-top: 20px;
	margin-bottom: 20px;
}

.load-more-button button {
	background-color: #112d4a;
	/* Button background color */
	color: #fff;
	/* Text color */
	border: none;
	border-radius: 4px;
	padding: 10px 20px;
	cursor: pointer;
	font-size: 16px;
	transition: background-color 0.2s;
}

.load-more-button button:hover {
	background-color: #23578f;
}

/* CSS for filter container */
.filter-wrapper {
	border-bottom: 1px solid rgba(0, 0, 0, 0.23);
	padding: 20px 0;
}

.filter-wrapper .MuiButtonBase-root {
	padding: 0;
	line-height: 1.5;
}

.filter-row {
	display: flex;
	gap: 20px;
	justify-content: end;
	align-items: center;

	@media (max-width: 768px) {
		align-items: end;
	}
}

.filter-select-list {
	flex: 1;
	display: flex;
	gap: 20px;
	align-items: center;

	@media (max-width: 1199px) {
		flex-wrap: wrap;
	}
}
.border-1{
	border: 1px solid black;
	display: flex;
	/* width: 200px; */
	/* justify-content: end; */
}
.abc_input{
	width: 500px !important;
}
.filter-select-list1 {
	flex: 1;
	display: flex;
	gap: 20px;
	align-items: center;
	justify-content: flex-end;
	border: #23578f solid 1px;

	@media (max-width: 1199px) {
		flex-wrap: wrap;
		justify-content: flex-end;
	}
}

.filter-container {
	margin-top: 10px;
}

.filter {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin: 0 !important;

	@media (max-width: 1199px) {
		width: 30.33%;
	}

	@media (max-width: 767px) {
		width: 100%;
	}
}

.filter-label {
	font-size: 16px;
	margin-bottom: 5px;
	color: #333;
}

.filter-select {
	padding: 10px;
	/* border: 2px solid #007bff; */
	border-radius: 5px;
	font-size: 14px;
	background-color: #fff;
	color: #333;
	cursor: pointer;
	transition: border-color 0.3s;
	width: 200px;

	@media (max-width: 768px) {
		width: 100%;
	}

	@media (max-width: 480px) {
		font-size: 12px;
		padding: 8px;
	}
}

.filter-select:focus {
	/* outline: none;
	border-color: #0056b3;
	box-shadow: 0 0 5px rgba(0, 86, 179, 0.5); */
}

.filter-select option {
	font-size: 14px;
	background-color: #fff;
	color: #333;
}

.filter-select option[value=""] {
	font-weight: bold;
	color: #888;
}

.reset-filter {
	display: inline-block;
	cursor: pointer;
	margin-right: 0px;
	color: #007bff;

	@media (max-width: 768px) {
		margin-right: 10px;
	}
}

.image-details {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 10px;
	background-color: white;
	border: 1px solid #ddd;
	border-radius: 0 0 5px 5px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	transition: transform 0.2s ease-in-out;
	cursor: pointer;
}

.image-name {
	font-size: 15px !important;
	font-weight: bold !important;
	margin: 0;
	color: #555;
	text-align: left !important;
	display: flex;
	align-items: center;
}

.image-size,
.design-category {
	font-size: 12px;
	margin: 0;
	padding: 2px 0 0 0;
	color: #555;
	font-weight: normal !important;
	text-align: left !important;
	/* Adjust color as needed */
	display: flex;
	align-items: center;
}

.image-name svg,
.image-size svg,
.design-category svg,
.finish-name svg {
	margin-right: 5px;
}

.finish-name {
	font-size: 12px;
	margin: 0;
	padding: 2px 0 0 0;
	color: #555;
	font-weight: normal !important;
	text-align: left !important;
	/* Prevent wrapping */
	overflow: hidden !important;
	/* Hide any overflow */
	text-overflow: ellipsis !important;
	display: flex;
	align-items: center;
}

.select-image-name {
	font-size: 14px;
	font-weight: normal !important;
	margin: 0;
	color: #555;
	padding-right: 5px;
	text-align: left;
}

.image-no-of-randome {
	font-weight: normal !important;
	/* padding: 0px 15px 0px 15px; */
	color: #555;
	/* background-color: black; */
	/* border-radius: 50%;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); */
	font-size: 15px !important;
	font-weight: bold;
	margin: 0;
	position: absolute;
	top: 15px;
	right: 0;
	margin-top: 0 !important;
}

.selected-image-count {
	background-color: #ff5733;
	color: #fff;
	border-radius: 50%;
	padding: 4px;
	position: absolute;
	top: -7px;
	/* Adjust the top position */
	right: -3px;
	/* Adjust the right position */
	font-size: 14px;
	min-width: 24px;
	min-height: 24px;
	line-height: 16px;
}

.selected-image-count::before {
	content: attr(data-count);
	/* Display the count attribute as content */
}

/* Style for the scrollable menu of the Select component */
/* .MuiMenu-paper {
	&::-webkit-scrollbar {
		width: 10px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #112d4a;
		border-radius: 6px;
	}

	&::-webkit-scrollbar-thumb:hover {
		background-color: #112d4a;
	}

	&::-webkit-scrollbar-track {
		background-color: transparent;
	}
} */

.custom-scrollbar-class {
	&::-webkit-scrollbar {
		width: 10px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #112d4a;
		border-radius: 6px;
	}

	&::-webkit-scrollbar-thumb:hover {
		background-color: #112d4a;
	}

	&::-webkit-scrollbar-track {
		background-color: #f1f1f1;
	}
}

/* Style for the MenuItem components */
.MuiMenuItem-root {
	&:hover {
		background-color: #f0f0f0;
		/* Change the background color of the MenuItem on hover */
	}
}

/* Add this CSS to your styles or CSS file */
.loader {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border: 8px solid #f3f3f3;
	border-top: 8px solid #3498db;
	border-radius: 50%;
	width: 50px;
	height: 50px;
	animation: spin 1s linear infinite;
	z-index: 9999;
	/* Set a high z-index value */
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}


.scroll-to-top {
	position: fixed;
	bottom: 20px;
	right: 20px;
	background-color: #112d4a;
	/* Change the background color */
	color: #fff;
	/* Change the text color */
	border: none;
	border-radius: 100%;
	padding: 0;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	z-index: 2;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.scroll-to-top-select-item {
	position: fixed;
	bottom: 20px;
	right: 20px;
	background-color: #112d4a;
	/* Change the background color */
	color: #fff;
	/* Change the text color */
	border: none;
	border-radius: 20%;
	padding: 0;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	z-index: 2;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.scroll-to-top i {
	font-size: 24px;
}

/* Add this CSS to your stylesheet or in your component style block */

.no-data-message {
	text-align: center;
	padding: 20px;
	font-size: 18px;
	color: #555;
	/* Choose a color that fits your design */
	background-color: #f8f8f8;
	/* Choose a background color */
	border: 1px solid #ddd;
	/* Add a border for better visibility */
	border-radius: 5px;
	/* Optional: Add border-radius for rounded corners */
	margin: 20px 0;
	/* Optional: Add margin to give some spacing */
}

/* Add these styles to your CSS stylesheet */
.loader-container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1;
}

.main_loader {
	border: 8px solid #f3f3f3;
	/* Light gray border */
	border-top: 8px solid #3498db;
	/* Blue border on top */
	border-radius: 50%;
	width: 50px;
	height: 50px;
	animation: spin 1s linear infinite;
	/* Spin animation */
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.button-container {
	align-items: center;
	justify-content: space-between;
	margin-top: 6px;
	margin-bottom: 100px;
}

.button-barcode {
	background-color: #2ca4c9;
}

.image-overlay {
	position: absolute;
	top: 10px;
	left: 10px;
	background-color: rgba(0, 0, 0, 0.7);
	color: #fff;
	padding: 11px 15px;
	border-radius: 50%;
	font-size: 14px;
}

image-overlay-popup {
	position: absolute;
	top: 10px;
	left: 10px;
	background-color: rgba(0, 0, 0, 0.7);
	color: #fff;
	padding: 11px 15px;
	border-radius: 50%;
	font-size: 14px;
}

.image-random {
	margin: 0;
}

/* Add these styles to your existing CSS file or create a new one */

.popup-container {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: white;
	padding: 20px;
	border: 1px solid #ccc;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	z-index: 999;
	width: 300px;
}

.popup-content {
	text-align: center;
}

.popup-content h2 {
	margin-bottom: 20px;
}

.form-group {
	margin-bottom: 15px;
}

.form-group label {
	display: block;
	margin-bottom: 5px;
}

.form-group input {
	width: 100%;
	padding: 8px;
	box-sizing: border-box;
}

/* Add more styles based on your design needs */
/* Add these styles to your existing CSS file or create a new one */

.status-message {
	margin-top: 10px;
}

.success {
	color: #4caf50;
	/* Green */
}

.error {
	color: #f44336;
	/* Red */
}

* {
	padding: 0;
	margin: 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

body {
	padding: 0;
}

.modal-dialog {
	max-width: 730px;
}

.wrap-modal-slider {
	padding: 0 30px;
	opacity: 0;
	transition: all 0.3s;
}

.wrap-modal-slider.open {
	opacity: 1;
}

.slick-prev:before,
.slick-next:before {
	color: red;
}

.flex-container {
	display: flex;
	justify-content: space-between;
	margin-top: 7px;
}

.modal-img {
	transition: opacity 0.5s ease, transform 0.5s ease;
}

.slider-wrapper {
	width: 400px;
	height: 250px;
	border: 1px solid #333;
	overflow: hidden;
	border-radius: 4px !important;
}

.slider {
	width: 2000px;
}

.slider-item {
	float: left;
	position: relative;
	text-transform: uppercase;
	color: #fff;
	line-height: 250px;
	font-size: 20px;
	background: #383838;
	text-align: center;
	border-radius: 4px !important;
	transition: all .2s linear;
}

.hide {
	@extend .slider-item
}

.buttons-wrapper {
	width: 400px;
	display: flex;
	justify-content: space-between;
	position: absolute;
	top: 50%;
	margin-top: -15px;
}

.buttons-wrapper .prev-button {
	background-image: url(http://static.birgun.net/static/images/slider-arrow-left.svg);
	background-size: 100%;
	background-color: rgba(0, 0, 0, 0.2);
	height: 30px;
	width: 30px;
	border-radius: 4px;
	color: #fff;
	border: none;
	cursor: pointer;
	outline: none;
	transition: all 0.2s linear;
}

.buttons-wrapper .next-button {
	background-image: url(http://static.birgun.net/static/images/slider-arrow-right.svg);
	@extend .prev-button;
}

.buttons-wrapper button:hover {
	background-color: rgba(0, 0, 0, 0.4);
}


/* indicators */

.indicators {
	width: 400px;
	position: absolute;
	bottom: -4px;
	z-index: 2 !important;
	text-align: center;
}

.indicators li {
	display: inline-block;
	margin-right: 5px;
	width: 12px;
	height: 12px;
	font-size: 16px;
	line-height: 30px;
	border-radius: 100%;
	text-align: center;
	background-color: rgba(255, 255, 255, 0.8);
	color: #333;
	text-indent: -9999px;
	cursor: pointer;
	transition: all 0.2s linear;
}

.indicators li:last-child {
	margin-right: 0;
}

.active-indicator {
	background: rgba(0, 0, 0, 0.3) !important;
	color: #fff !important;
}

/* For the fade-in effect */
.fade-in {
	/* opacity: 0; */
	animation: fade-in 0.5s ease-in-out;
}

.slide-left {
	animation: slide-left 0.5s ease-in-out;
}

.slide-right {
	animation: slide-right 0.5s ease-in-out;
}

@keyframes slide-left {
	from {
		transform: translateX(100%);
	}

	to {
		transform: translateX(0);
	}
}

@keyframes slide-right {
	from {
		transform: translateX(-100%);
	}

	to {
		transform: translateX(0);
	}
}

.select-all-label {
	display: flex;
	align-items: center;
	font-size: 16px;
	margin-bottom: 10px;
	cursor: pointer;
}

.select-all-checkbox {
	margin-right: 8px;
	cursor: pointer;
}

.checkmark {
	width: 18px;
	height: 18px;
	border: 2px solid #3498db;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 8px;
}

.checkmark-icon {
	color: #3498db;
	font-size: 14px;
}

.select-all-label-text {
	color: #333;
}

.select-all-label:hover {
	text-decoration: underline;
}

.index-counter {
	position: absolute;
	top: 10px;
	left: 50%;
	transform: translateX(-50%);
	background-color: rgba(0, 0, 0, 0.7);
	color: #fff;
	padding: 5px 10px;
	border-radius: 5px;
	font-size: 16px;
	transition: opacity 0.3s ease-in-out;
}

.index-counter:hover {
	opacity: 0.8;
}

.design-type-container {
	position: absolute;
	top: 0;
	left: 0;
	padding: 8px;
	background-color: #112d4a;
	color: #fff;
	border-top-left-radius: 8px;
	border-bottom-right-radius: 8px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.design-type {
	margin: 0;
	font-size: 14px;
}

.design_download {
	cursor: pointer;
	margin-top: 7px;
}

.gallery-modal .modal-content {
	background-color: transparent;
	padding: 10px 8px 20px;
	box-shadow: none;
}

.gallery-modal .close-icon {
	color: #fff;
	top: -8px;
	right: 9px;
	z-index: 1;
	font-size: 18px;
}

.gallery-modal .flex-container {
	color: #fff;
	font-size: 12px;
}

.gallery-modal .btnPrev,
.gallery-modal .btnNext {
	color: #fff;
}

.gallery-pop-image {
	background-color: #000;
	border-radius: 8px;
}

.gallery-pop-image img {
	vertical-align: middle;
	border-radius: 8px;
}

.gallery-pop-image .design_download {
	color: #fff;
}


.MuiAutocomplete-listbox::-webkit-scrollbar-track,
.MuiPaper-root::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: #F5F5F5;
}

.MuiAutocomplete-listbox::-webkit-scrollbar,
.MuiPaper-root::-webkit-scrollbar {
	width: 6px;
	background-color: #F5F5F5;
}

.MuiAutocomplete-listbox::-webkit-scrollbar-thumb,
.MuiPaper-root::-webkit-scrollbar-thumb {
	background-color: #112d4a;
}

.MuiOutlinedInput-notchedOutline {
	transition: all 0.5s ease-in-out;
}

.MuiCheckbox-root.MuiButtonBase-root {
	padding-left: 0 !important;
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

.MuiCheckbox-root .MuiSvgIcon-root {
	display: none !important;
}

.MuiCheckbox-root input[type="checkbox"] {
	position: absolute;
	left: 0;
	top: 0;
	/* Ensure checkbox is above the image */
	width: 100%;
	/* Set the width of the checkbox */
	height: 100%;
	/* Set the height of the checkbox */
	opacity: 0;
	/* Hide the default checkbox */
}

/* Style for the custom checkbox */
.MuiCheckbox-root input[type="checkbox"]~.MuiTouchRipple-root {
	width: 20px;
	height: 20px;
	background-color: #fff;
	/* Background color of the checkbox */
	border: 2px solid #112d4a;
	/* Border color */
	border-radius: 4px;
	/* Rounded corners */
	cursor: pointer;
	display: inline-block;
	vertical-align: top;
	position: relative;
	overflow: visible;
	transition: background-color 0.2s, border-color 0.2s;
}

/* Style for the custom checkbox when checked */
.MuiCheckbox-root input[type="checkbox"]:checked~.MuiTouchRipple-root {
	background-color: #112d4a;
	/* Background color when checked */
	border-color: #112d4a;
	/* Border color when checked */
}

/* Style for the custom checkbox when checked with a checkmark */
.MuiCheckbox-root input[type="checkbox"]:checked~.MuiTouchRipple-root::after {
	content: '\2713';
	/* Checkmark symbol */
	display: block;
	color: #fff;
	/* Checkmark color */
	text-align: center;
	font-size: 30px;
	/* Adjust the size of the checkmark */
	line-height: 100%;
	height: 20px;
	width: 20px;
	margin-top: -12px;
}

.MuiCheckbox-root+.MuiListItemText-root {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}

.MuiAutocomplete-option[aria-selected="true"],
.Mui-selected {
	background-color: #b9c1c5 !important;
}

.MuiSelect-select {
	text-align: left;
}

.MuiAutocomplete-listbox {
	padding: 0 !important;
}

.MuiAutocomplete-listbox li,
.MuiList-padding li {
	padding-top: 10px;
	padding-bottom: 10px;
}

.MuiInputLabel-formControl.Mui-focused {
	color: #5293b2 !important;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
	border-color: #5293b2 !important;
}

/* Add this CSS to your existing stylesheet */
.scroll-to-top-button {
	position: fixed;
	bottom: 70px;
	right: 20px;
	background-color: #112d4a;
	color: #fff;
	border: none;
	border-radius: 100%;
	padding: 0;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	z-index: 2;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.scroll-to-top-button i {
	font-size: 24px;
}

.image-details {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	padding: 10px;
	background-color: #f8f8f8;
	border-radius: 8px;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
}

.masonry-text-data {
	display: flex;
	gap: 5px;
	/* Adjust the gap as needed */
}

.masonry-text-data p {
	margin: 0;
	font-style: italic;
}

.masonry-text-data p:not(:last-child)::after {
	content: '|';
	margin-left: 5px;
	/* Adjust the spacing as needed */
}

.masonry-text-effect {
	position: relative;
	padding-right: 40px;
}

.masonry-text-effect p {
	font-size: 9px;
	margin: 0;
}

.attractive-total {
	background-color: #f0f0f0;
	padding: 10px;
	border-radius: 5px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	text-align: center;
	font-size: 12px;
	font-family: 'Your-Italic-Font', sans-serif;
	font-style: italic;
	/* font-weight: bold; */
	color: #333;
}

.reset-icon {
	position: absolute;
	top: 50%;
	right: 280px;
	transform: translateY(-50%);
	cursor: pointer;
}

@media screen and (max-width: 319px) {
	.reset-icon {
		top: 75%;
		right: 335px;
		/* Adjust the right value for small screens */
	}
}

@media only screen and (min-width: 320px) and (max-width: 575px) {
	.reset-icon {
		top: 75%;
		right: 240px;
		/* Adjust the right value for small screens */
	}
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
	.reset-icon {
		top: 75%;
		right: 355px;
		/* Adjust the right value for medium-sized screens */
	}
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.reset-icon {
		right: 275px;
		/* Adjust the right value for medium-sized screens */
	}
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.reset-icon {
		right: 270px;
		/* Adjust the right value for larger medium-sized screens */
	}
}

.sticky-header {
	margin: 0;
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	background-color: #fff;
	/* Add background color if needed */
	z-index: 100;
}

.drawer-header {
	display: flex;
	justify-content: space-between;
	/* align-items: center; */
	flex-direction: column;
	padding: 5px 10px;
}

.header-row,
.action-row {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 7px 10px;
}

.close-drawer-icon-select-item {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	right: 0;
	padding: 0px 10px 0px 10px;
	/* Add padding for better visibility */
	background-color: #fff;
	/* Add background color if needed */
	z-index: 100;
	cursor: pointer;
}

/* Inside your component's CSS or in your styles file */
.user-name {
	font-size: 16px;
	/* Adjust the font size as needed */
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 150px;
	/* Adjust the maximum width as needed */
	display: inline-block;
	/* Ensures ellipsis works on inline elements */
	vertical-align: middle;
	/* Aligns text vertically in the middle */
}

/* Additional styles for ellipsis on hover (optional) */
.user-name:hover {
	overflow: visible;
	white-space: normal;
	z-index: 1;
}

/* Styles for the generated information */
.generated-info {
	margin-top: 10px;
	padding: 10px;
	display: flex;
	gap: 25px;
	justify-content: space-between;
	/* background-color: #e0f7fa; */
	/* Light blue background color, adjust as needed */
	/* border: 1px solid #29b6f6; */
	/* Border color, adjust as needed */
	border-radius: 5px;
}

/* Styles for the info label */
.info-label {
	font-weight: bold;
}

/* Styles for the info text */
.info-text {
	cursor: pointer;
	display: inline-block;
	max-width: 200px;
	/* Adjust the maximum width as needed */
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	vertical-align: middle;
}

/* Styles for the copy icon */
.copy-icon {
	cursor: pointer;
	margin-left: 5px;
	font-size: 16px;
	color: #007BFF;
	/* Adjust the color as needed */
}

.repeater-container {
	margin-top: 10px;
}

.icon {
	color: #112d4a;
	font-size: 20px;
	cursor: pointer;
}

.textarea-styled {
	margin-right: 10px;
	min-width: 200px;
	min-height: 50px;
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 5px;
	resize: vertical;
	font-size: 14px;
}

.file-name {
	display: flex;
	align-items: center;
	margin-left: 10px;
}

.file-icon {
	font-size: 15px;
	margin-right: 5px;
	color: #3498db;
	/* Adjust the color as needed */
}

.file-text {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: 12px;
	/* max-width: 200px; */
	/* Adjust the max-width as needed */
	color: #333;
	/* Adjust the color as needed */
}

.attachment-grid {
	display: grid;
	/* Adjust the minmax values as needed */
	gap: 10px;
	margin-top: 10px;
}

/* Two columns for screens wider than 600px */
@media screen and (min-width: 600px) {
	.attachment-grid {
		grid-template-columns: repeat(1, 1fr);
	}
}

/* Three columns for screens wider than 900px */
@media screen and (min-width: 900px) {
	.attachment-grid {
		grid-template-columns: repeat(2, 1fr);
	}
}

/* Four columns for screens wider than 1200px */
@media screen and (min-width: 1200px) {
	.attachment-grid {
		grid-template-columns: repeat(2, 1fr);
	}
}

.design-image-grid {
	display: grid;
	/* Adjust the minmax values as needed */
	gap: 10px;
	margin-top: 10px;
}

/* Two columns for screens wider than 600px */
@media screen and (min-width: 600px) {
	.design-image-grid {
		grid-template-columns: repeat(3, 1fr);
	}
}

/* Three columns for screens wider than 900px */
@media screen and (min-width: 900px) {
	.design-image-grid {
		grid-template-columns: repeat(3, 1fr);
	}
}

/* Four columns for screens wider than 1200px */
@media screen and (min-width: 1200px) {
	.design-image-grid {
		grid-template-columns: repeat(4, 1fr);
	}
}

/* Five columns for screens wider than 1500px */
@media screen and (min-width: 1500px) {
	.design-image-grid {
		grid-template-columns: repeat(5, 1fr);
	}
}

/* Six columns for screens wider than 1800px */
@media screen and (min-width: 1800px) {
	.design-image-grid {
		grid-template-columns: repeat(6, 1fr);
	}
}

.attachment-item {
	text-align: center;
}

.attachment-image {
	max-width: 100%;
	max-height: 200px;
	/* Adjust the max-height as needed */
}

.message-container {
	display: flex;
	align-items: center;
}

.message-row {
	display: flex;
	align-items: center;
	gap: 10px;
}

.message-style {
	background-color: #f8f8f8;
	border-radius: 8px;
	padding: 10px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	max-width: 300px;
	word-wrap: break-word;
	margin: 0;
	margin-bottom: 5px;
}

.editable-input .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
	height: 42px;
}

/* CSS to hide the default text in the file input */
input[type="file"] {
	display: none;
}

/* Style for the custom label */
.file-input-label {
	display: inline-block;
	padding: 11px 5px;
	cursor: pointer;
	border: 1px solid #ccc;
	border-radius: 4px;
}

/* Optional: Style the label to make it look like a button */
.file-input-label:hover {
	background-color: #f0f0f0;
}


.bookWrapper {
	width: 600px;
	height: 400px;
}

.bookBg {
	position: relative;
	background-color: #000;
	width: 100%;
	height: 100%;
	border-radius: 12px;
}

.pageBg {
	position: absolute;
	left: 50%;
	top: 50%;
	background-color: #999;
	width: calc(100% - 20px);
	height: calc(100% - 20px);
	border-radius: 0px;
}

.pageWrapper {
	position: absolute;
	width: 50%;
	height: 100%;
	float: left;
	-webkit-font-smoothing: antialiased;
}

.page {
	position: absolute;
	width: 100%;
	height: 100%;
}

.pageFace {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	text-align: center;
}

.front {
	background: linear-gradient(to right, #d9d9d9 0%, #f9f9f9 3%, #ffffff 8%, #ffffff 100%);
}

.back {
	background: linear-gradient(to right, #ffffff 0%, #ffffff 92%, #f9f9f9 97%, #d9d9d9 100%);
}

.pageFoldRight {
	position: absolute;
	width: 0px;
	height: 0px;
	top: 0;
	right: 0;
	border-left-width: 1px;
	border-left-color: #DDDDDD;
	border-left-style: solid;
	border-bottom-width: 1px;
	border-bottom-color: #DDDDDD;
	border-bottom-style: solid;
	box-shadow: -5px 5px 10px #dddddd;
}

.pageFoldLeft {
	position: absolute;
	width: 0px;
	height: 0px;
	top: 0;
	left: 0;
	border-right-width: 1px;
	border-right-color: #DDDDDD;
	border-right-style: solid;
	border-bottom-width: 1px;
	border-bottom-color: #DDDDDD;
	border-bottom-style: solid;
	box-shadow: 5px 5px 10px #dddddd;
}

.modal-content-wrapper {
	position: absolute;
	bottom: 40px;
	left: 0;
	width: 100%;
	padding: 20px;
	justify-content: center;
	align-items: center;
	left: 35%;
}

@media screen and (max-width: 992px) {
	.modal-content-wrapper {
		left: 35%;
		bottom: 100px;
		/* Adjust for tablets */
	}
}

@media screen and (max-width: 768px) {
	.modal-content-wrapper {
		left: 35%;
		bottom: 100px;
		/* Adjust for larger mobile devices and smaller tablets */
	}
}

@media screen and (max-width: 576px) {
	.modal-content-wrapper {
		left: 7%;
		bottom: 20px;
		/* Adjust for smaller mobile devices */
	}
}

.compare-design-image-grid {
	display: flex;
}

.comparison-container {
	width: 50%;
}

.comparison-images {
	display: flex;
	flex-wrap: wrap;
	padding: 8px;
	margin-left: 1px;
}

.design-image-image {
	width: calc(33.33% - 10px);
	/* Adjust as needed */
	margin: 5px;
	/* Adjust as needed */
}

.compare-button-container {
	position: fixed;
	bottom: 120px;
	right: 10px;
	z-index: 9999;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.compare {
	font-size: 17px;
	color: white;
	padding: 0px;
	padding-top: 5px;
	background-color: #112d4a;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	margin-top: 10px;
}

.compare-reset {
	font-size: 25px;
	color: white;
	padding: 0px;
	background-color: #112d4a;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.suggestion-item {
	padding: 8px;
	border-radius: 8px;
	color: #333;
	cursor: pointer;
	transition: all 0.3s ease;
}

.suggestion-item:hover {
	background-color: #f8f9fa;
	color: #007bff;
	transform: translateY(-2px);
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Style for the suggestion container */
.react-autosuggest__suggestions-container {
	position: absolute;
	top: calc(100% - 10px);
	/* Adjusted spacing from bottom of input */
	left: 56%;
	/* Centered horizontally */
	transform: translateX(-50%);
	width: 90%;
	/* Adjusted width for responsiveness */
	max-width: 600px;
	/* Maximum width */
	max-height: 300px;
	/* Maximum height */
	overflow-y: auto;
	/* border-radius: 8px; */
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	background-color: #fff;
}

/* Adjust input field width */
.react-autosuggest__input {
	width: 100%;
	height: 40px;
	padding: 10px 20px;
	font-family: Helvetica, sans-serif;
	font-weight: 300;
	font-size: 16px;
	border: 1px solid #aaa;
	border-radius: 4px;
	box-sizing: border-box;
}

/* Suggestions list styles */
.react-autosuggest__suggestions-list {
	list-style-type: none;
	padding: 0;
	margin: 0;
	width: 100%;
	border: 2px solid #aaa;
	/* Match width of input */
}

/* Individual suggestion item */
.react-autosuggest__suggestion {
	padding: 0px;
	font-size: 10px;
	color: #333;
	cursor: pointer;
	text-align: left;
}

/* Highlighted suggestion */
.react-autosuggest__suggestion--highlighted {
	background-color: #f0f0f0;
}

/* Media queries for different screen sizes */
@media screen and (min-width: 1200px) {
	.react-autosuggest__input {
		width: 600px;
		/* Width for large screens */
	}
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
	.react-autosuggest__input {
		width: 333px;
		/* Width for medium screens */
	}
}

@media screen and (min-width: 576px) and (max-width: 767px) {
	.react-autosuggest__input {
		width: 400px;
		/* Width for small screens */
	}
}

@media screen and (max-width: 575px) {
	.react-autosuggest__input {
		width: 100%;
		/* Full width for very small screens */
	}
}

/* Media queries for responsiveness */

@media screen and (max-width: 1200px) {
	.react-autosuggest__suggestions-container {
		left: 47%;
		/* Centered horizontally */
		width: 43%;
		/* Adjusted width */
	}
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
	.react-autosuggest__suggestions-container {
		left: 43%;
		/* Centered horizontally */
		width: 43%;
		/* Adjusted width */
	}
}

@media screen and (min-width: 576px) and (max-width: 767px) {
	.react-autosuggest__suggestions-container {
		left: 50%;
		/* Centered horizontally */
		width: 90%;
		/* Adjusted width */
	}
}

@media screen and (max-width: 575px) {
	.react-autosuggest__suggestions-container {
		left: 50%;
		/* Centered horizontally */
		width: 90%;
		/* Adjusted width */
	}
}

@media screen and (max-width: 319px) {
	.react-autosuggest__suggestions-container {
		left: 50%;
		/* Centered horizontally */
		width: 90%;
		/* Adjusted width */
	}
}
