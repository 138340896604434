/* ErrorPage.css */

.error-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	background-color: #f8f8f8;
}

.error-content {
	text-align: center;
	padding: 20px;
	border-radius: 8px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	background-color: #fff;
}

button {
	background-color: #3498db;
	/* Blue color for the button */
	color: #fff;
	padding: 10px 20px;
	margin-top: 20px;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	font-size: 16px;
	transition: background-color 0.3s ease;
}

button:hover {
	background-color: #2980b9;
	/* Darker blue on hover */
}
